<template>
  <page-title-component title="Forms"></page-title-component>

  <div class="row">
    <div class="col-md-12">
      <table-component
        :columns="columns"
        :rows="forms"
        :sortable="sortable"
        @sorting="sorting"
        :pagination="pagination"
        @search="search"
        @pageChanged="pageChanged">

        <template v-slot:actions>
          <router-link class="btn btn-sm btn-success"
                       :to="{name: 'forms_add'}">
            <i class="fas fa-plus"></i> Add Form
          </router-link>
        </template>

        <template v-slot:action="{ row }">
          <router-link :to="{name: 'forms_view', params: {id: row.id}}"
                       class="btn btn-sm btn-info me-1">
            <i class="fas fa-eye"></i>
          </router-link>

<!--          <router-link :to="{name: 'forms_edit', params: {id: row.id}}"
                       class="btn btn-sm btn-warning me-1">
            <i class="fas fa-edit"></i>
          </router-link>-->

          <a type="button"
             class="btn btn-sm btn-danger"
             @click.prevent="deleteData(row)">
            <i class="fas fa-trash"></i>
          </a>
        </template>
      </table-component>
    </div>
  </div>
</template>

<script>
import TableComponent from "@/components/TableComponent";
import PageTitleComponent from "@/components/PageTitleComponent";
export default {
  components: {TableComponent, PageTitleComponent},
  data() {
    return {
      forms: [],
      queryParams: {
        page: 1,
        sort: 'created_at',
        sort_order: 'desc',
        per_page: 10
      },
      sortable: {
        order: 'created_at',
        sort: 'desc'
      },
      columns: [
        {
          label: "Name",
          field: 'name',
          sortable: true,
          searchable: true,
        },
        {
          label: "Action",
          field: 'action',
          sortable: false,
          slot: true,
          class: ['text-end']
        }
      ],
      pagination: null,
    }
  },
  created() {
    this.loadData();
  },
  methods: {
    loadData() {
      this.forms = [];

      this.axios.get('/forms', {params: this.queryParams})
        .then((response) => {
          this.forms = response.data.data;
          this.pagination = response.data.meta;
        })
    },
    deleteData(row) {
      this.$swal({
        title: 'Are you sure want to delete?',
        text: "You won't be able to revert this!",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Yes, delete it!',
        reverseButtons: true
      }).then((result) => {
        if (result.isConfirmed) {
          this.axios.delete('/forms/'+row.id).then(() => {
            this.loadData();
            this.$swal.fire(
              'Deleted',
              'Form has been deleted!',
              'success'
            )
          });
        }
      });
    },
  }
}
</script>