<template>
  <div class="modal fade"
       id="modal-add-new-data"
       tabindex="-1"
       role="dialog"
       aria-hidden="true">
    <div class="modal-dialog" role="document">
      <div class="modal-content">
        <div class="modal-header">
          <h6 class="modal-title m-0 me-3" id="exampleModalDefaultLabel">Add New</h6>
          <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
        </div><!--end modal-header-->
        <div class="modal-body">
          <div class="mb-1 row">
            <label class="col-sm-4 form-label align-self-center mb-lg-0 text-end">First Name</label>
            <div class="col-sm-8">
              <input class="form-control"
                     v-model="form.first_name"
                     type="text">
            </div>
          </div>
          <div class="mb-1 row">
            <label class="col-sm-4 form-label align-self-center mb-lg-0 text-end">Middle Name</label>
            <div class="col-sm-8">
              <input class="form-control"
                     v-model="form.middle_name"
                     type="text">
            </div>
          </div>
          <div class="mb-1 row">
            <label class="col-sm-4 form-label align-self-center mb-lg-0 text-end">Last Name</label>
            <div class="col-sm-8">
              <input class="form-control"
                     v-model="form.last_name"
                     type="text">
            </div>
          </div>
          <div class="mb-1 row">
            <label class="col-sm-4 form-label align-self-center mb-lg-0 text-end">Contact Phone Number</label>
            <div class="col-sm-8">
              <input class="form-control"
                     v-model="form.contact_phone_number"
                     type="text">
            </div>
          </div>
          <div class="mb-1 row">
            <label class="col-sm-4 form-label align-self-center mb-lg-0 text-end">Date of Birth</label>
            <div class="col-sm-8">
              <input class="form-control"
                     v-model="form.date_of_birth"
                     type="text">
            </div>
          </div>
        </div><!--end modal-body-->
        <div class="modal-footer">
          <button type="button" class="btn btn-soft-secondary btn-sm" data-bs-dismiss="modal">Close</button>
          <button type="button"
                  @click="save"
                  v-if="!loading"
                  class="btn btn-soft-primary btn-sm">Add</button>

          <button class="btn btn-soft-primary btn-sm"
                  v-else
                  type="button"
                  disabled="">
              <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
              Loading...
          </button>
        </div>
      </div><!--end modal-content-->
    </div><!--end modal-dialog-->
  </div><!--end modal-->
</template>

<script>
export default {
  data() {
    return {
      modal: null,
      form: {
        first_name: '',
        middle_name: '',
        last_name: '',
        contact_phone_number: '',
        date_of_birth: '',
      }
    }
  },
  mounted() {
    this.modal = new window.bootstrap.Modal(document.getElementById('modal-add-new-data'))
  },
  methods: {
    show() {
      this.modal.show();
    },
    hide() {
      this.modal.hide();
    },
    save() {
      this.loading = true;

      this.axios.post('/intake/new/data', this.form)
        .then(() => {
          this.$emit('added');
          this.hide();
        })
        .finally(() => this.loading = false)
    }
  }
}
</script>