<template>
  <page-title-component :title="title"></page-title-component>

  <div class="row">
    <div class="col-12">
      <div class="table-responsive">
        <table class="table">
          <thead>
          <tr>
            <th>Name</th>
            <th>View</th>
          </tr>
          </thead>

          <tbody v-if="form">
          <tr>
            <td>{{ form.intake_form_name }}</td>
            <td>
              <a class="btn btn-sm btn-info"
                 target="_blank"
                 :href="form.intake_form_link">View</a>
            </td>
          </tr>
          <tr>
            <td>{{ form.note_name }}</td>
            <td>
              <a class="btn btn-sm btn-info"
                 target="_blank"
                 :href="form.note_link">View</a>
            </td>
          </tr>
          <tr>
            <td>{{ form.template_name }}</td>
            <td>
              <a class="btn btn-sm btn-info"
                 target="_blank"
                 :href="form.template_link">View</a>
            </td>
          </tr>
          <tr>
            <td>Intake Response</td>
            <td>
              <a class="btn btn-sm btn-info"
                 target="_blank"
                 :href="form.intake_response_link">View</a>
            </td>
          </tr>
          <tr>
            <td>Note Response</td>
            <td>
              <a class="btn btn-sm btn-info"
                 target="_blank"
                 :href="form.note_response_link">View</a>
            </td>
          </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</template>

<script>
import PageTitleComponent from "@/components/PageTitleComponent";
export default {
  components: {PageTitleComponent},
  data() {
    return {
      title: '',
      form: null
    }
  },
  created() {
    this.axios.get('/forms/'+this.$route.params.id)
      .then(response => {
        this.form = response.data.data;
        this.title = this.form.name;

      })
  }
}
</script>