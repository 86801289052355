<template>
  <div class="row dataTables_wrapper dt-bootstrap5 no-footer">
    <div class="col-sm-12 col-md-5">
      <div class="dataTables_info">{{props.count}}</div>
    </div>

    <div class="col-sm-12 col-md-7">
      <div class="dataTables_paginate paging_simple_numbers" id="datatable_paginate">
        <ul class="pagination">
          <li v-if="props.hasEdgeNav"
              @click="props.setFirstPage"
              class="paginate_button page-item">
            <a href="javascript:void(0)" v-bind="props.firstPageProps">{{props.texts.first}}</a>
          </li>

          <li v-if="props.hasChunksNav"
              @click="props.setPrevChunk"
              class="paginate_button page-item">
            <a href="javascript:void(0)" v-bind="props.prevChunkProps">{{props.texts.prevChunk}}</a>
          </li>

          <li @click="props.setPrevPage"
              class="paginate_button page-item">
            <a href="javascript:void(0)" v-bind="props.prevProps">{{props.texts.prevPage}}</a>
          </li>

          <li v-for="page in props.pages" :key="page"
              :class="['paginate_button page-item', {'active': page === props.page}]"
              v-on="props.pageEvents(page)">
            <a href="javascript:void(0)" v-bind="props.aProps" :class="props.theme.link">{{page}}</a>
          </li>

          <li @click="props.setNextPage"
              class="paginate_button page-item">
            <a href="javascript:void(0)" v-bind="props.nextProps">{{props.texts.nextPage}}</a>
          </li>

          <li v-if="props.hasChunksNav"
              @click="props.setNextChunk"
              class="paginate_button page-item">
            <a href="javascript:void(0)" v-bind="props.nextChunkProps">{{props.texts.nextChunk}}</a>
          </li>

          <li v-if="props.hasEdgeNav"
              @click="props.setLastPage"
              class="paginate_button page-item">
            <a href="javascript:void(0)" v-bind="props.lastPageProps">{{props.texts.last}}</a>
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'PaginationComponent',
  props: ['props']
}
</script>