<template>
  <page-title-component title="Responses"></page-title-component>

  <div class="row">
    <div class="col-12">
      <button class="btn btn-sm btn-primary me-1"
              @click="$refs.importExcelInput.click()">
        <i class="fas fa-file-import"></i> Import Excel
      </button>

      <button class="btn btn-sm btn-primary me-1"
              @click="addNewResponse">
        <i class="fas fa-plus"></i> Add New
      </button>

      <button class="btn btn-sm btn-secondary"
              v-if="!checkingNewFiles"
              @click="checkNewFiles">
        <i class="fas fa-sync-alt"></i> Check New Files
      </button>

      <button class="btn btn-sm btn-secondary"
              v-else
              type="button"
              disabled="">
          <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
          Checking...
      </button>
    </div>
  </div>

  <hr>

  <div class="row">
    <div class="col-md-12">
      <div class="d-flex justify-content-center"
           v-if="fetchingData">
          <div class="spinner-border text-primary" role="status"></div>
      </div>

      <p v-else-if="!fetchingData && !columns.length">No Data Found.</p>

      <table class="table table-hover table-striped" v-else>
        <thead>
        <tr>
          <th v-for="(column, i) in columns" :key="'column_'+i">
            {{ column }}
          </th>
          <th class="text-end" v-if="columns.length">Actions</th>
        </tr>
        </thead>
        <tbody>
        <tr v-for="(response, i) in responses" :key="'response_'+i">
          <td v-for="j in columns.length" :key="'data_'+i+'_'+j">
            {{ response[j-1]}}
          </td>
          <td class="text-end">
            <button @click="showFiles(response[0])"
                    :disabled="loading"
                    class="btn btn-sm btn-info me-1">
              <i class="fas fa-file"></i> View Files
            </button>

            <button @click="showNote(response[0])"
                    :disabled="loading"
                    class="btn btn-sm btn-info">
              <i class="fas fa-sticky-note"></i> Documents & Notes
            </button>
          </td>
        </tr>
        </tbody>
      </table>
    </div>
  </div>

  <show-files ref="showFilesModal" />
  <note-modal ref="noteModal" />
  <add-new-data-modal ref="addNewModal"
                      @added="loadData" />
  <excel-import-mapping-modal @added="loadData"
                              ref="excelImportMappingModal" />

  <input @change="readExcelFile($event.target)"
         type="file"
         accept=".xlsx, .xls"
         ref="importExcelInput" style="display: none">
</template>

<script>
import PageTitleComponent from "@/components/PageTitleComponent";
import AddNewDataModal from "./AddNewDataModal";
import ExcelImportMappingModal from "@/components/ExcelImportMappingModal.vue";
import ShowFiles from "./ShowFiles";
import NoteModal from "./NoteModal";
import {mapGetters} from "vuex";
import axios from "@/util/axios";

export default {
  components: {PageTitleComponent, AddNewDataModal, NoteModal, ShowFiles,
    ExcelImportMappingModal},
  data() {
    return {
      checkingNewFiles: false,
      fetchingData: false,
      responses: [],
      allResponses: [],
      queryParams: {
        page: 1,
        sort: 'created_at',
        sort_order: 'desc',
        per_page: 10
      },
      columns: [],
      pagination: null,
    }
  },
  computed: {
    ...mapGetters({
      form: "form/getForm",
      settings: "settings/getSettings"
    })
  },
  created() {
    this.loadData();
  },
  methods: {
    checkNewFiles() {
      this.loading = true;
      this.checkingNewFiles = true;

      this.axios.post('/check/files')
        .then(() => {
          this.loadData();
          this.showSuccessMsg("Checked New Files")
        })
        .finally(() => {
          this.loading = false
          this.checkingNewFiles = false
        })
    },
    getResponseFiles(id) {
      return new Promise((resolve, reject) => {
        axios.get("/response/files?patient_id="+id).then((response) => {
          resolve(response.data.data);
        }).catch((error) => {
          reject(error.response.data)
        });
      });
    },
    showFiles(patientId) {
      this.$refs.showFilesModal.show(patientId);
    },
    getNoteFormIframe(id) {
      return new Promise((resolve, reject) => {
        axios.post("/note/iframe", {
          'patient_id': id
        }).then((response) => {
          resolve(response.data.url);
        }).catch((error) => {
          reject(error.response.data)
        });
      });
    },
    showNote(patientId) {
      this.loading = true;

      Promise.all( [this.getResponseFiles(patientId), this.getNoteFormIframe(patientId)] )
        .then((results) => {
          this.$refs.noteModal.show(results[1], results[0]);
        })
        .finally(() => this.loading = false);
    },
    addNewResponse() {
      this.$refs.addNewModal.show();
    },
    search({q, columns}) {
      this.queryParams.page = 1;
      this.queryParams.search = q;
      this.queryParams.search_columns = columns;

      this.responses = this.allResponses.filter(object => Object.values(object).some(i => (i+"").toLowerCase().includes(q.toLowerCase())));
    },
    sorting({column, order}) {
      this.queryParams.sort = column;
      this.queryParams.sort_order = order;
      this.queryParams.page = 1;

      let c = [...this.responses];
      if (order === 'asc')
        this.responses = window._.orderBy(c, [column], ['asc']);
      else
        this.responses = window._.orderBy(c, [column], ['desc']);
    },
    readExcelFile(input) {
      if (input.files && input.files[0]) {
        this.$refs.excelImportMappingModal.show(input.files[0]);
      }
    },
    loadData() {
      this.responses = [];
      this.columns = [];
      this.allResponses = [];
      this.fetchingData = true;

      this.axios.get('/intake/responses')
        .then((response) => {
          this.columns = response.data[0];
          response.data.shift();

          this.responses = response.data;
        })
        .finally(() => {
          this.fetchingData = false;
        })
    },
  }
}
</script>