<template>
  <page-title-component :title="title"></page-title-component>

  <div class="row">
    <div class="col-md-12">
      <div class="card">
        <div class="card-body">
          <div class="row">
            <div class="col-md-6">
              <div class="form-group">
                <label>Project Name</label>
                <input type="text"
                       :disabled="loading"
                       :class="['form-control', {'is-invalid': errorFor('name')}]"
                       v-model="form.name">
                <v-errors :errors="errorFor('name')" />
              </div>
            </div>

            <div class="col-md-6">
              <div class="form-group">
                <label>Intake Form</label>

                <select :class="['form-select', {'is-invalid': errorFor('intake_form_id')}]"
                        :disabled="loading"
                        v-model="form.intake_form_id">
                  <option value="">Select Intake Form</option>
                  <option :value="intakeForm.id"
                          v-for="(intakeForm, i) in intakeForms"
                          :key="'form_'+i">{{ intakeForm.name }}</option>
                </select>
                <v-errors :errors="errorFor('intake_form_id')" />
              </div>
            </div>
          </div>

          <div class="row">
            <div class="col-md-6">
              <div class="form-group">
                <label>Note Form</label>

                <select :class="['form-select', {'is-invalid': errorFor('note_id')}]"
                        :disabled="loading"
                        v-model="form.note_id">
                  <option value="">Select Note Form</option>
                  <option :value="noteForm.id"
                          v-for="(noteForm, i) in noteForms"
                          :key="'form_'+i">{{ noteForm.name }}</option>
                </select>
                <v-errors :errors="errorFor('note_id')" />
              </div>
            </div>
          </div>

          <hr>

          <div class="row">
            <div class="col-md-6">
              <h5>Intake Form</h5>
              <table class="table table-bordered table-striped">
                <thead>
                <tr>
                  <th>Name</th>
                  <th>Action</th>
                </tr>
                </thead>
                <tbody>
                <tr v-for="intake in intakeForms"
                    :key="'intake_'+intake.id">
                  <td>{{ intake.name }}</td>
                  <td>
                    <a class="btn btn-sm btn-info"
                       :href="'https://docs.google.com/forms/d/' + intake.id" target="_blank">View Example Form</a>
                  </td>
                </tr>
                </tbody>
              </table>
            </div>

            <div class="col-md-6">
              <h5>Note Form</h5>
              <table class="table table-bordered table-striped">
                <thead>
                <tr>
                  <th>Name</th>
                  <th>Action</th>
                </tr>
                </thead>
                <tbody>
                <tr v-for="note in noteForms"
                    :key="'note_'+note.id">
                  <td>{{ note.name }}</td>
                  <td>
                    <a class="btn btn-sm btn-info"
                       :href="'https://docs.google.com/forms/d/' + note.id" target="_blank">View Example Form</a>
                  </td>
                </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>

        <div class="card-footer">
          <div class="row">
            <div class="col-sm-12 text-end">
              <!-- <router-link :to="{name: 'forms'}"
                           class="btn btn-secondary me-1">Cancel</router-link> -->

              <button type="submit"
                      class="btn btn-success mr-1"
                      @click.prevent="submit"
                      v-if="!loading">Continue</button>

              <button class="btn btn-success" type="button" disabled v-else>
                <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                Loading...
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import PageTitleComponent from "@/components/PageTitleComponent";

export default {
  components: {PageTitleComponent},
  data() {
    return {
      title: 'Add Practice',
      intakeForms: [],
      noteForms: [],
      form: {
        intake_form_id: '',
        template_id: '',
        note_template_id: '',
        template_name: '',
        note_template_name: '',
        note_id: '',
        name: '',
      }
    }
  },
  created() {
    this.axios.get('/intake-forms')
      .then(response => {
        response.data.values.forEach((v, i) => {
          if (i > 0) {
            this.intakeForms.push({
              id: v[0],
              name: v[1],
              template_id: v[2],
              template_name: v[3],
            })
          }
        })
      });

    this.axios.get('/note-forms')
      .then(response => {
        response.data.values.forEach((v, i) => {
          if (i > 0) {
            this.noteForms.push({
              id: v[0],
              name: v[1],
              template_id: v[2],
              template_name: v[3],
            })
          }
        })
      });
  },
  methods: {
    submit() {
      this.allErrors = null;
      this.loading = true;

      if (this.form.intake_form_id) {
        this.form.template_id = this.intakeForms.find(form => form.id === this.form.intake_form_id).template_id;
        this.form.template_name = this.intakeForms.find(form => form.id === this.form.intake_form_id).template_name;
      } else {
        this.form.template_id = '';
        this.form.template_name = '';
      }

      if (this.form.note_id) {
        this.form.note_template_id = this.noteForms.find(form => form.id === this.form.note_id).template_id;
        this.form.note_template_name = this.noteForms.find(form => form.id === this.form.note_id).template_name;
      } else {
        this.form.note_template_id = '';
        this.form.note_template_name = '';
      }

      this.axios.post('/forms/validation', this.form)
        .then(() => {
          this.$router.push({
            name: 'form_preview',
            params: {
              intake: this.form.intake_form_id,
              note: this.form.note_id,
              name: this.form.name,
              template: this.form.template_id,
              template_name: this.form.template_name,
              note_template: this.form.note_template_id,
              note_template_name: this.form.note_template_name,
            }
          });
          // this.showSuccessMsg('Added Successfully!')
        })
        .catch((err) => {
          this.allErrors = err.response.data.errors;
        })
        .finally(() => {
          this.loading = false;
        });
    }
  }
}
</script>