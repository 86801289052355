<template>
  <div class="modal fade"
       id="modal-add-note"
       tabindex="-1"
       role="dialog"
       aria-hidden="true">
    <div class="modal-dialog  modal-xl" :class="expand ? 'full-modal': 'small-modal'" role="document">
      <div class="modal-content text-center">
        <div class="modal-header">
          <h6 class="modal-title m-0 me-3" id="exampleModalDefaultLabel">Documents & Notes</h6>
          <div class="flex-grow-1">

          </div>
          <a @click="expand = !expand">
            <i class="fas fa-solid fa-expand text-white mx-2"></i>
          </a>
          <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>

        </div><!--end modal-header-->
        <div class="modal-body" v-if="url">
          <div class="row">
            <div class="col-md-6">
              <div class="accordion" id="accordionExample">
                <div class="accordion-item"
                     v-for="file in allFiles"
                     :key="'file_'+file.id">
                  <h5 class="accordion-header m-0"
                      :id="'heading_'+file.id">
                    <button class="accordion-button fw-semibold collapsed"
                            type="button"
                            data-bs-toggle="collapse"
                            :data-bs-target="'#collapse_'+file.id"
                            aria-expanded="false"
                            :aria-controls="'collapse_'+file.id">
                      {{ file.file_name }} - {{ file.created_at }}
                    </button>
                  </h5>
                  <div :id="'collapse_'+file.id"
                       class="accordion-collapse collapse"
                       :aria-labelledby="'heading_'+file.id"
                       data-bs-parent="#accordionExample">
                    <div class="accordion-body text-center">
                      <div class="alert alert-danger border-0"
                           v-if="file.deleted"
                           role="alert">
                        <strong>This file has been removed.</strong>
                      </div>

                      <iframe :src="file.url"
                              v-else
                              style="width: 100%;"
                              class="pdf-iframe"
                              frameborder="0"
                              marginheight="0"
                              marginwidth="0"></iframe>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-md-6">
              <iframe :src="url"
              style="width: 100%;"
              class="fp-iframe"
                      frameborder="0"
                      marginheight="0"
                      marginwidth="0"></iframe>
            </div>
          </div>

        </div><!--end modal-body-->
      </div><!--end modal-content-->
    </div><!--end modal-dialog-->
  </div><!--end modal-->
</template>

<script>
export default {
  data() {
    return {
      modal: null,
      url: null,
      expand: false,
      allFiles: []
    }
  },
  mounted() {
    this.modal = new window.bootstrap.Modal(document.getElementById('modal-add-note'))
  },
  methods: {
    show(url, allFiles) {
      this.allFiles = allFiles;
      this.url = url;
      this.modal.show();
    },
    hide() {
      this.modal.hide();
    },
  }
}
</script>

<style>
.full-modal .fp-iframe {
    height:calc(100vh - 100px)!important
}
.full-modal .pdf-iframe {
    height:calc(100vh - 160px)!important
}
.full-modal.modal-dialog{
  max-width: 100%!important;
  max-height: 100vh!important;
  margin: 0px!important;
} 
.full-modal .modal-content {
  height: 100vh!important;
}
.full-modal .modal-body {
  height: 100%!important;
}
.small-modal iframe{
  height:  600px;
}
</style>