<template>
  <page-title-component title="Continue Form Setup"></page-title-component>
  <p>
    Complete your Intake and Treatment Notes forms setup here. Select the items
    from each form template that you'd like to include in the forms you use for
    your practice. You'll have the ability to do more detailed changes to
    wording and format later.
  </p>
  <div class="modal fade" id="modal-form-loader"  data-backdrop="static" data-keyboard="false">
    <div class="modal-dialog modal-dialog-centered" role="document">
      <div class="modal-content">
        <div class="modal-body d-flex align-items-center">
          <div class="spinner-border text-primary" role="status"></div>
          <div class="mx-3">
            Please wait while we customize the forms for your practice...
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="modal fade" id="modal-loader"  data-backdrop="static" data-keyboard="false">
    <div class="modal-dialog modal-dialog-centered modal-sm" role="document">
      <div class="modal-content">
        <div class="modal-body d-flex align-items-center">
          <div class="spinner-border text-primary" role="status"></div>
          <div class="mx-3">
            Loading
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="row">
    <div class="col-md-12">
      <div class="card">
        <div class="card-body">
          <!-- Nav tabs -->
          <ul class="nav nav-pills nav-justified" role="tablist">
            <li class="nav-item waves-effect waves-light">
              <a
                class="nav-link active"
                data-bs-toggle="tab"
                href="#intake"
                role="tab"
                aria-selected="true"
                >Intake Form Setup</a
              >
            </li>
            <li class="nav-item waves-effect waves-light">
              <a
                class="nav-link"
                data-bs-toggle="tab"
                href="#note"
                role="tab"
                aria-selected="false"
                >Treatment Note Setup</a
              >
            </li>
          </ul>

          <!-- Tab panes -->
          <div class="tab-content">
            <div class="tab-pane active" id="intake" role="tabpanel">
              <div class="row mt-3" v-if="intakeLoaded">
                <div class="col-md-6">
                  <div class="mb-3">
                    <label class="form-label">Title</label>
                    <input
                      type="text"
                      :class="[
                        'form-control',
                        { 'is-invalid': errorFor('intake.title') },
                      ]"
                      v-model="form.intake.title"
                    />

                    <v-errors :errors="errorFor('intake.title')" />
                  </div>

                  <div class="mb-3">
                    <label class="form-label">Description</label>
                    <textarea
                      :class="[
                        'form-control',
                        { 'is-invalid': errorFor('intake.description') },
                      ]"
                      rows="15"
                      v-model="form.intake.description"
                    ></textarea>

                    <v-errors :errors="errorFor('intake.description')" />
                  </div>
                </div>

                <div class="col-md-6">
                  <label class="form-label">Items</label>

                  <ul
                    class="list-group"
                    style="max-height: 400px; overflow: scroll"
                  >
                    <li
                      class="list-group-item"
                      v-for="(item, i) in form.intake.items"
                      :key="'item_' + i"
                    >
                      <div class="form-check">
                        <input
                          class="form-check-input"
                          type="checkbox"
                          value="1"
                          v-model="item.selected"
                        />
                        <label class="form-check-label">
                          {{ item.title }}
                        </label>
                      </div>
                    </li>
                  </ul>
                </div>
              </div>
              <div class="d-flex justify-content-center mt-3" v-else>
                <div class="spinner-border text-primary" role="status"></div>
              </div>
            </div>
            <div class="tab-pane" id="note" role="tabpanel">
              <div class="row mt-3" v-if="noteLoaded">
                <div class="col-md-6">
                  <div class="mb-3">
                    <label class="form-label">Title</label>
                    <input
                      type="text"
                      :class="[
                        'form-control',
                        { 'is-invalid': errorFor('note.title') },
                      ]"
                      v-model="form.note.title"
                    />

                    <v-errors :errors="errorFor('note.title')" />
                  </div>

                  <div class="mb-3">
                    <label class="form-label">Description</label>
                    <textarea
                      :class="[
                        'form-control',
                        { 'is-invalid': errorFor('note.description') },
                      ]"
                      rows="15"
                      v-model="form.note.description"
                    ></textarea>

                    <v-errors :errors="errorFor('note.description')" />
                  </div>
                </div>

                <div class="col-md-6">
                  <label class="form-label">Items</label>

                  <ul
                    class="list-group"
                    style="max-height: 400px; overflow: scroll"
                  >
                    <li
                      class="list-group-item"
                      v-for="(item, i) in form.note.items"
                      :key="'item_' + i"
                    >
                      <div class="form-check">
                        <input
                          class="form-check-input"
                          type="checkbox"
                          :disabled="isDisabled(item)"
                          value="1"
                          v-model="item.selected"
                        />
                        <label class="form-check-label">
                          {{ item.title }}
                        </label>
                      </div>
                    </li>
                  </ul>
                </div>
              </div>
              <div class="d-flex justify-content-center mt-3" v-else>
                <div class="spinner-border text-primary" role="status"></div>
              </div>
            </div>
          </div>
        </div>

        <div class="card-footer">
          <div class="row">
            <div class="col-sm-12 text-end">
              <router-link
                :to="{ name: 'forms_add' }"
                v-if="!loading"
                class="btn btn-secondary me-1"
                >Cancel</router-link
              >

              <button
                type="submit"
                class="btn btn-success mr-1"
                @click.prevent="submit"
                v-if="!loading"
              >
                Complete Setup
              </button>

              <button class="btn btn-success" type="button" disabled v-else>
                <span
                  class="spinner-border spinner-border-sm"
                  role="status"
                  aria-hidden="true"
                ></span>
                Loading...
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import PageTitleComponent from "@/components/PageTitleComponent";
export default {
  components: { PageTitleComponent },
  data() {
    return {
      intakeLoaded: false,
      noteLoaded: false,
      loader: null,
      formLoader: null,
      form: {
        intake_id: this.$route.params.intake,
        note_id: this.$route.params.note,
        name: this.$route.params.name,
        template: this.$route.params.template,
        template_name: this.$route.params.template_name,
        note_template: this.$route.params.note_template,
        note_template_name: this.$route.params.note_template_name,
        intake: {
          title: "",
          description: "",
          items: [],
        },
        note: {
          title: "",
          description: "",
          items: [],
        },
      },
    };
  },
  created() {
    // this.axios
    //   .get("/google-form/" + this.$route.params.intake)
    //   .then((response) => {
    //     this.form.intake = response.data.data;
    //     this.form.intake.items = this.form.intake.items.map((item) => {
    //       return {
    //         title: item,
    //         selected: true,
    //       };
    //     });
    //   })
    //   .finally(() => {
    //     this.intakeLoaded = true;
    //   });
    // this.axios
    //   .get("/google-form/" + this.$route.params.note)
    //   .then((response) => {
    //     this.form.note = response.data.data;
    //     this.form.note.items = this.form.note.items.map((item) => {
    //       return {
    //         title: item,
    //         selected: true,
    //       };
    //     });
    //   })
    //   .finally(() => {
    //     this.noteLoaded = true;
    //   });
  },
  mounted() {
    this.modal = new window.bootstrap.Modal(
      document.getElementById("modal-loader"),
      {
    backdrop: 'static',  
    keyboard: true       
}
    );
    this.formLoader = new window.bootstrap.Modal(
      document.getElementById("modal-form-loader"),
      {
    backdrop: 'static',  
    keyboard: true       
}
    );
    this.fetchForm()
  },
  methods: {
    async fetchForm() {
      this.modal.show();
      try {
        let intakeRes = await this.axios.get(
          "/google-form/" + this.$route.params.intake
        );
        this.form.intake = intakeRes.data.data;
        this.form.intake.items = this.form.intake.items.map((item) => {
          return {
            title: item,
            selected: true,
          };
        });
        let noteRes = await this.axios.get(
          "/google-form/" + this.$route.params.note
        );
        this.form.note = noteRes.data.data;
        this.form.note.items = this.form.note.items.map((item) => {
          return {
            title: item,
            selected: true,
          };
        });
        this.intakeLoaded = true;
        this.noteLoaded = true;
        this.modal.hide();
      } catch (error) {
        this.modal.hide();
        alert(error.message);
      }
    },
    isDisabled({ title }) {
      return title == "Patient ID";
    },
    submit() {
      this.allErrors = null;
      this.loading = true;
      this.formLoader.show();
      this.axios
        .post("/forms", this.form)
        .then((response) => {
          this.formLoader.hide();
          this.$store.commit("form/setForm", response.data.data);
          this.$router.push({ name: "dashboard" });
          this.showSuccessMsg("Added Successfully!");
        })
        .catch((err) => {
          console.log("Error message");
          this.formLoader.hide();
          this.allErrors = err.response.data.errors;
          alert(err.message);
        })
        .finally(() => {
          this.formLoader.hide();
          this.loading = false;
        });
    },
  },
};
</script>