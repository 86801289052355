<template>
  <div class="modal fade"
       id="modal-show-files"
       tabindex="-1"
       role="dialog"
       aria-hidden="true">
    <div class="modal-dialog modal-lg" role="document">
      <div class="modal-content">
        <div class="modal-header">
          <h6 class="modal-title m-0 me-3" id="exampleModalDefaultLabel">
            Patient Files
            <button class="btn btn-sm btn-primary ms-3"
                    @click="$refs.fileInput.click()">Add File</button>
          </h6>
          <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
        </div><!--end modal-header-->
        <div class="modal-body">
          <div class="d-flex justify-content-center" v-if="loading">
            <div class="spinner-border" role="status">
              <span class="visually-hidden"></span>
            </div>
          </div>

          <div class="accordion" id="accordionExample" v-else>
            <div class="accordion-item"
                 v-for="file in files"
                 :key="'file_'+file.id">
              <h5 class="accordion-header m-0"
                  :id="'heading_'+file.id">
                <button class="accordion-button fw-semibold collapsed"
                        type="button"
                        data-bs-toggle="collapse"
                        :data-bs-target="'#collapse_'+file.id"
                        aria-expanded="false"
                        :aria-controls="'collapse_'+file.id">
                  {{ file.file_name }} - {{ file.created_at }}
                </button>
              </h5>
              <div :id="'collapse_'+file.id"
                   class="accordion-collapse collapse"
                   :aria-labelledby="'heading_'+file.id"
                   data-bs-parent="#accordionExample">
                <div class="accordion-body text-center">
                  <div class="alert alert-danger border-0"
                       v-if="file.deleted"
                       role="alert">
                    <strong>This file has been removed.</strong>
                  </div>

                  <iframe :src="file.url"
                          v-else
                          width="640"
                          height="500"
                          frameborder="0"
                          marginheight="0"
                          marginwidth="0"></iframe>
                </div>
              </div>
            </div>
          </div>
        </div><!--end modal-body-->
      </div><!--end modal-content-->
    </div><!--end modal-dialog-->
  </div><!--end modal-->

  <input @change="readDocumentURL($event.target)"
         type="file"
         accept="application/pdf"
         ref="fileInput" style="display: none">
</template>

<script>
import axios from "@/util/axios";

export default {
  data() {
    return {
      modal: null,
      patientId: null,
      files: []
    }
  },
  mounted() {
    this.modal = new window.bootstrap.Modal(document.getElementById('modal-show-files'))
  },
  methods: {
    show(patientId) {
      this.patientId = patientId;
      this.modal.show();

      this.loadFiles(patientId)
    },
    hide() {
      this.modal.hide();
    },
    loadFiles(patientId) {
      this.loading = true;
      this.files = [];

      this.getResponseFiles(patientId)
        .then(res => {
          this.files = res;
        })
        .finally(() => this.loading = false)
    },
    getResponseFiles(id) {
      return new Promise((resolve, reject) => {
        axios.get("/response/files?patient_id="+id).then((response) => {
          resolve(response.data.data);
        }).catch((error) => {
          reject(error.response.data)
        });
      });
    },
    readDocumentURL(input) {
      if (input.files && input.files[0]) {
        this.loading = true;
        let formData = new FormData();

        formData.append('file', input.files[0]);
        formData.append('patient_id', this.patientId);

        this.axios.post('/upload/file', formData)
          .then(() => {
            this.loadFiles(this.patientId);
          });
      }
    }
  }
}
</script>